.window-card {
    width: 100vw;
    height: 100vh;

    position: absolute; 
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    .window-card-container {
        width: 100%;
        // height: 100%;
        max-width: 600px;
        // max-height: 490px;

        background-color: rgba(255, 255, 255, 0.87);
        backdrop-filter: blur(15px);
        border-radius: 15px;
        // border: #FFFFFF 1px solid;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;

        pointer-events: all;

        position: relative;

        transition: 0.2s;

        .window-card-cross {
            
            position: absolute;
            z-index: 5;
            top: 16.5px;
            right: 20px;

            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
            
            transition: 0.2s;

            img {
                width: 19px;
                height: 19px;
            }
        }

        .window-card-inner {
            // width: calc(100% - 6px);
            // height: calc(100% - 6px);
            width: 100%;
            height: 100%;

            border-radius: 15px;

            .window-card-gallery {
                width: 100%;
                height: 100%;
                
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                
                border-radius: 10px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                transition: 0.2s;

                .window-card-gallery-arrow {
                    height: 80%;
                    z-index: 2;

                    cursor: pointer;

                    display: flex;
                    align-items: center;

                    user-select: none;

                    img {
                        width: 15px;
                        transition: 0.2s;
                        
                        user-select: none;
                    }
                }

                .window-card-gallery-arrow-left {
                    padding-right: 25%;
                    img {
                        margin-left: 15px;
                    }
                }

                .window-card-gallery-arrow-right {
                    padding-left: 25%;
                    img {
                        margin-right: 15px;
                    }
                }

                .window-card-gallery-back {
                    width: 100%;
                    height: 100%;
                    
                    border-radius: 10px;

                    position: fixed;
                    z-index: 1;
                }

                .window-card-gallery-circles {
                    width: 100%;
                    bottom: 35px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    position: fixed;
                    z-index: 2;
                    
                    .window-card-gallery-circles-inner {
                        display: flex;

                        .window-card-gallery-circle {
                            width: 10px;
                            height: 10px;
                            margin-right: 7px;
    
                            border-radius: 100px;
    
                            transition: 0.2s;
                            cursor: pointer;
    
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .window-card-contact {
                width: 100%;
                
                border-radius: 15px;
                
                padding-top: 60px;
                padding-bottom: 60px;

                background-size: cover;

                .window-card-contact-inner {
                    width: 100%;
                    max-width: 70%;

                    margin: 0 auto;

                    color: #FFFFFF;

                    .window-card-contact-title {
                        font-family: 'LAMORE' !important;
                        font-size: 30px;

                        margin-bottom: 4px;
                    }

                    .window-card-contact-desc {
                        font-family: 'Montserrat' !important;
                        font-size: 10.5px;
                        font-weight: 400;

                        margin-bottom: 15px;
                    }
                    
                    input {
                        box-sizing: border-box;
                        background: transparent;

                        padding: 10px 0;

                        color: #FFFFFF;
                        border: none;
                        border-bottom: 1px solid #e1e3e4;
                        outline: none;

                        font-size: 12px;
                        font-family: 'Montserrat' !important;
                        
                        &::placeholder {
                            color: #FFFFFF;
                        }
                    }

                    .window-card-contact-input {
                        width: 100%;
                        margin-bottom: 10px;

                        input {
                            width: 100%;
                        }
                    }

                    .window-card-contact-phone {
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        margin-bottom: 20px;

                        .PhoneInput {
                            width: 100%;

                            .PhoneInputCountry {
                                margin-right: 10px;

                            }
                        }

                        .PhoneInputCountryIcon--border {
                            box-shadow: 0 0 0 1px rgb(122, 129, 148) !important;
                        }
                    }

                    .window-card-contact-button {
                        cursor: pointer;

                        font-family: 'Montserrat';
                        font-size: 14px;

                        color: #fff;
                        border: 1px solid #e1e3e4;
                        background: #ffffff1f;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 100px;
                        height: 30px;

                        user-select: none;
                    }

                    .window-card-contact-button-shake {
                        animation: shake 0.3s;
                      }
                      
                    @keyframes shake {
                        0% { transform: translateX(0); }
                        25% { transform: translateX(-3px); }
                        50% { transform: translateX(3px); }
                        75% { transform: translateX(-3px); }
                        100% { transform: translateX(0); }
                    }
                }
            }

            video {
                border-radius: 15px;
            }

            iframe {
                border-radius: 15px;
            }
        }
    }
}