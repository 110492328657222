.loading {
    position: absolute;
    z-index: 100;

    background-color: #FFFFFF;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 1s ease-out;
}