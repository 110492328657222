.main {
    
    .card {
        width: 100vw;
        height: 100vh;

        position: absolute;
        z-index: 9;
        pointer-events: none;

        display: flex;
        align-items: center;

        .card-container {
            position: fixed;
            right: 1.5%;
            
            max-width: 580px;
            width: 100%;

            transition: 0.2s;
            
            background-color: #08123088;
            backdrop-filter: blur(10px);
            box-shadow: inset 0 0 10px 5px rgba(255, 255, 255, 0.02);
            border-radius: 8px;
            border-radius: 15px;

            color: #FFFFFF;

            display: flex;
            justify-content: space-between;

            .card-exit {
                position: fixed;

                right: 20px;
                top: 20px;

                cursor: pointer;

                img {
                    width: 19px;

                    transition: 0.2s;
                }
            }

            .card-qr {
                position: absolute;
                right: 6.25%;
                top: 135px;

                img {
                    width: 80px;
                    border-radius: 7.5px;
                }
            }

            .card-top {
                max-width: 200px;
                width: 100%;
                height: 265px;
                border-radius: 8px;

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-end;

                transition: 0.2s;
                
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                margin-top: 35px;
                margin-left: 35px;
                margin-right: 25px;

                .card-top-circles {
                    display: flex;
                    margin-bottom: 20px;

                    position: fixed;

                    .card-top-circle {
                        width: 10px;
                        height: 10px;
                        margin-right: 7px;

                        border-radius: 100px;

                        transition: 0.2s;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .card-top-back {
                    width: 100%;
                    height: 100%;

                    border-radius: 15px 15px 0 0;

                    cursor: nesw-resize;
                }
            }

            .card-bottom {
                max-width: 87.5%;
                width: 100%;

                margin-top: 35px;
                margin-bottom: 35px;
                margin-right: 30px;
                
                .card-title {
                    font-size: 24px;
                    font-weight: 500;

                    margin-bottom: 12px;

                    max-width: calc(100% - 20px);
                }

                .card-desc {
                    font-size: 15px;
                    font-weight: 500;

                    line-height: 140%;

                    color: rgba(255, 255, 255, 0.747);

                    height: 150px;
                    overflow-y: auto;

                    padding-right: 10px;
                }

                .card-bottom-buttons {
                    max-width: 100%;
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .card-bottom-button {
                    width: 100%;
                    height: 60px;

                    margin-top: 20px;

                    border-radius: 5px;
                    background-color: #648aac;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    cursor: pointer;
                    transition: 0.2s;

                    .card-bottom-button-inner {
                        width: 100%;
                        
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        font-size: 15px;
                        font-weight: 500;

                        user-select: none;

                        img {
                            transition: 0.2s;
                        }
                    }
                }
            }
        }
    }
}