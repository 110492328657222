.main {

    -webkit-tap-highlight-color: transparent;

    .main-first {
        
        width: 100vw;
        
    }

}