.main {

    .map {
        width: 100vw;
        height: 100vh;

        .map-container {
            width: 100%;
            height: 100%;

            .gm-style-iw {
                border-radius: 3px !important;

                .gm-style-iw-d {
                    padding: 0 !important;

                    .directions-infowindow {
                        font-family: Inter;
                        font-size: 11px;
                        font-weight: 700;
                        color: rgb(0, 0, 0);
                        padding: 0 !important;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        img {
                            width: 15px;
                            margin-right: 10px;
                        }
                    }
                }

                .gm-ui-hover-effect {
                    display: none !important;
                }
            }


        }

    }

}