@font-face {
    font-family: 'HelveticaNeue';
    src: local(''), url(./static/fonts/HelveticaNeue.ttf) format('truetype');
}

@font-face {
    font-family: 'LAMORE';
    src: local(''), url(./static/fonts/lamore.otf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: local(''), url(./static/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

.App {
    font-family: HelveticaNeue, sans-serif !important;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
/* Track */
::-webkit-scrollbar-track {
    background-color: #44617980;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #648aacb0; 
    border-radius: 10px;
}
